import React, { useRef, useState } from 'react';
import { Alert, Form, Row, Col, Button, Spinner } from 'react-bootstrap';
import { restletGET } from '../../../apis/RestLetAPI';
import QRCodeScan from '../../QRCodeScan';

function ScanItemAsset(props) {
  console.log('scanasset inputs', props.inputs);
  const [isSubmitting, setSubmitting] = useState(null);
  const getAsset = (e) => {
    setSubmitting(true);
    e.preventDefault();
    e.stopPropagation();
    console.log('Submit form -> GET ASSET');
    restletGET({ type: 'getAsset', assetId: props.inputs.scanAssetText })
      .then((response) => {
        if (response.status === 200) {
          const { data } = response;
          console.log('asset fields', data);

          if (data.success) {
            props.handleChange({
              target: {
                name: `items-${props.inputs?.currentLineId}-assets`,
                value: data.details['Internal ID']
              }
            });
            props.handleChange({
              target: {
                name: `assetQuantity`,
                value: 1
              }
            });
            props.handleChange({
              target: {
                name: `scanAssetText`,
                value: ''
              }
            });
            props.nextStep(e);
          } else
            props.handleChange({ target: { name: 'errorMessage', value: `Error Getting Asset` } });
        }
      })
      .catch((error) => {
        console.log('error restletGET', error);
        props.handleChange({ target: { name: 'errorMessage', value: error.message } });
        console.log('inputs after error', props.inputs);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  const submitBtnRef = useRef();
  return (
    <>
      <h3>Scan Asset</h3>

      <Form onSubmit={getAsset}>
        {props.inputs.errorMessage ? (
          <Row className="mb-6">
            <Alert
              variant="danger"
              onClose={() => props.handleChange({ target: { name: 'errorMessage', value: '' } })}
              dismissible>
              <Alert.Heading>You got an error!</Alert.Heading>
              <p>{props.inputs.errorMessage}</p>
            </Alert>
          </Row>
        ) : (
          <></>
        )}
        <Row className="mb-2">
          <Form.Group as={Col} controlId="scanAsset">
            <Form.Control
              readOnly
              plaintext
              defaultValue={`${props.inputs.items[props.inputs?.currentLineId]?.name}: ${Number(props.inputs?.assetQuantity) + 1} of ${Number(props.inputs?.processingQuantity)}`}
            />
          </Form.Group>
        </Row>
        <Row className="mb-2">
          <Form.Group as={Col} controlId="scanAsset">
            <Form.Control
              type="text"
              name="scanAssetText"
              placeholder="Scan Tag ID or Manufacturer Serial Number"
              onChange={props.handleChange}
              defaultValue={props.inputs.scanAssetText}
              required
            />
          </Form.Group>
        </Row>
        <Row className="mb-2 ">
          <Form.Group as={Col}>
            <div className="d-grid gap-2">
              <QRCodeScan
                inputs={props.inputs}
                fieldPath="scanAssetText"
                submitBtnRef={submitBtnRef}
                isSubmitting={isSubmitting}
                handleChange={props.handleChange}
              />
            </div>
          </Form.Group>
        </Row>
        <Row className="mb-2 ">
          <Form.Group as={Col}>
            <div className="d-grid gap-2">
              {/*<Button variant="secondary" size="lg" onClick={props.prevStep}>*/}
              {/*  Back*/}
              {/*</Button>*/}
            </div>
          </Form.Group>
          <Form.Group as={Col}>
            <div className="d-grid gap-2">
              <Button
                disabled={isSubmitting}
                ref={submitBtnRef}
                type="submit"
                variant="primary"
                size="lg">
                {isSubmitting ? (
                  <Spinner animation="border" size="sm" aria-hidden="true" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : (
                  'Next'
                )}
              </Button>
            </div>
          </Form.Group>
        </Row>
      </Form>
    </>
  );
}

export default ScanItemAsset;
