import { Button, Card } from 'react-bootstrap';
function ActionCard(props) {
  return (
    <Card className="border-0">
      <Card.Body>
        <Button variant="outline-primary">
          {/* eslint-disable-next-line react/prop-types */}
          {props.logo} {props.text}
        </Button>
      </Card.Body>
    </Card>
  );
}

export default ActionCard;
