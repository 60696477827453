import React, { useState } from 'react';
import { Row, Button, Offcanvas, Figure } from 'react-bootstrap';

const ImagePanel = (props) => {
  const [showImages, setShowImages] = useState(false);
  const handleClose = () => setShowImages(false);
  const handleShow = () => setShowImages(true);
  return (
    <>
      <Button variant="warning" onClick={handleShow} className="me-2" size="lg">
        Show Images
      </Button>
      <Offcanvas placement="end" show={showImages} onHide={handleClose} {...props}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Captured Images</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row xs={1} md={2} className="m-2">
            {props.inputs.images.length ? (
              props.inputs.images.map((img, i) => (
                <Figure key={i}>
                  <Figure.Image width={200} height={200} alt="200x200" src={img} />
                </Figure>
              ))
            ) : (
              <></>
            )}
          </Row>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default ImagePanel;
