import React, { useState } from 'react';
import { Form, Row, Col, Button, Table, Spinner, Alert } from 'react-bootstrap';
import { restletPOST } from '../../../apis/RestLetAPI';
import { Link } from 'react-router-dom';
function SalesOrderSummary(props) {
  console.log('StandaloneRRSummary inputs', props.inputs);
  const [result, setResult] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const createItemFulfillment = (e) => {
    setSubmitting(true);
    e.preventDefault();
    e.stopPropagation();

    const data = {
      type: 'createItemFulfillment',
      data: {
        driversName: props.inputs?.driversName,
        shippersName: props.inputs?.driversName,
        waybillNo: props.inputs?.waybillNo,
        salesOrderId: props.inputs?.currentSoId,
        items: []
      }
    };
    Object.values(props.inputs.items)
      .filter((item) => Number(item.sessionProcessedQuantity) > 0)
      .forEach((item) => {
        data.data.items.push({
          item: item.id,
          quantity: item.sessionProcessedQuantity,
          location: item.locationId,
          inventoryDetails: item.inventoryDetails,
          assets: item.assets
        });
      });
    restletPOST({ data })
      .then((response) => {
        if (response.status === 200) {
          console.log('response', response.data);
          const data = response.data;
          if (response.data.success)
            setResult(`Item Fulfillment #${data.details} has been created (id:${data.details})`);
          else setResult(`Error when creating Item Fulfillment`);

          console.log('result', result);
        }
      })
      .catch((error) => {
        console.log(error);
        setResult(error.message);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <>
      {result ? (
        <>
          <Row className="mb-6">
            <Alert
              key="result"
              variant={result.toLowerCase().includes('error') ? 'danger' : 'success'}>
              {result}
            </Alert>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col}>
              <div className="d-grid gap-2">
                <Link to={'/'}>
                  <Button variant="primary" size="lg">
                    Back To Dashboard
                  </Button>
                </Link>
              </div>
            </Form.Group>
          </Row>
        </>
      ) : (
        <>
          <h3>Summary</h3>
          <Form onSubmit={createItemFulfillment}>
            <Row className="mb-2">
              <Form.Group as={Col} controlId="containerList">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.inputs.items ? (
                      Object.values(props.inputs.items)
                        .filter((item) => Number(item.sessionProcessedQuantity) > 0)
                        .map((item, i) => (
                          <tr key={i}>
                            <td>{item.displayName}</td>
                            <td>{item.sessionProcessedQuantity}</td>
                          </tr>
                        ))
                    ) : (
                      <tr key={-1}>
                        <td colSpan={2}>
                          <Spinner animation="border" role="status" variant="primary">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Form.Group>
            </Row>

            <Row className="mb-2">
              <Form.Group as={Col} controlId="driversName">
                <Form.Label>Driver&apos;s Name</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={props.inputs.driversName}
                  name="driversName"
                  required
                  onChange={props.handleChange}
                />
              </Form.Group>
              <Form.Group as={Col} controlId="waybillNo">
                <Form.Label>Waybill Number</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={props.inputs.waybillNo}
                  name="waybillNo"
                  onChange={props.handleChange}
                />
              </Form.Group>
            </Row>
            <Row className="mb-2">
              <Form.Group as={Col} controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  defaultValue={props.inputs.email}
                  name="email"
                  required
                  onChange={props.handleChange}
                />
              </Form.Group>
            </Row>
            <Row className="mb-2 ">
              <Form.Group as={Col}>
                <div className="d-grid gap-2">
                  <Button variant="secondary" size="lg" onClick={props.prevStep}>
                    Back
                  </Button>
                </div>
              </Form.Group>
              <Form.Group as={Col}>
                <div className="d-grid gap-2">
                  <Button disabled={isSubmitting} type="submit" variant="primary" size="lg">
                    {isSubmitting ? (
                      <Spinner animation="border" size="sm" aria-hidden="true" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    ) : (
                      'Submit'
                    )}
                  </Button>
                </div>
              </Form.Group>
            </Row>
          </Form>
        </>
      )}
    </>
  );
}

export default SalesOrderSummary;
