import React from 'react';
import { Form, Row, Col, Button, Table, Spinner } from 'react-bootstrap';

function ContainerSelection(props) {
  console.log('ContainerSelection inputs', props.inputs);
  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        e.stopPropagation();
        props.nextStep(e);
      }}>
      <Row className="mb-2">
        <Form.Group as={Col} controlId="containerList">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Quantity</th>
                <th>Container Type</th>
                {/*<th>ID</th>*/}
              </tr>
            </thead>
            <tbody>
              {props.inputs.containers ? (
                Object.values(props.inputs.containers).map((container, i) => (
                  <tr key={i}>
                    <td>
                      <Form.Control
                        type="number"
                        maxLength="10"
                        defaultValue={container.quantity || 0}
                        name={`containers-${container.id}-quantity`}
                        required
                        onChange={props.handleChange}
                      />
                    </td>
                    <td>{container.displayName}</td>
                    {/*<td className=".d-none">{container.id}</td>*/}
                  </tr>
                ))
              ) : (
                <tr key="-1">
                  <td colSpan={3}>
                    <Spinner animation="border" role="status" variant="primary">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  </td>
                </tr>
              )}
              <tr>
                <td colSpan={3}>
                  Total Quantity Received:{' '}
                  {props.inputs.containers
                    ? Object.values(props.inputs.containers).reduce(
                        (accu, el) => accu + Number(el.quantity),
                        0
                      )
                    : ''}
                </td>
              </tr>
            </tbody>
          </Table>
        </Form.Group>
      </Row>
      <Row className="mb-2">
        <Form.Group as={Col} controlId="remarks">
          <Form.Label>Initial Remarks</Form.Label>
          <Form.Control
            type="text"
            defaultValue={props.inputs.remarks}
            name="remarks"
            required
            onChange={props.handleChange}
          />
        </Form.Group>
      </Row>
      <Row className="mb-2 ">
        <Form.Group as={Col}>
          <div className="d-grid gap-2">
            <Button variant="secondary" size="lg" onClick={props.prevStep}>
              Back
            </Button>
          </div>
        </Form.Group>
        <Form.Group as={Col}>
          <div className="d-grid gap-2">
            <Button type="submit" variant="primary" size="lg">
              Next
            </Button>
          </div>
        </Form.Group>
      </Row>
    </Form>
  );
}

export default ContainerSelection;
