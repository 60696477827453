import React, { useEffect, useState } from 'react';
import CustomerInfo from './CustomerInfo';
import ContainerSelection from './ContainerSelection';
import StandaloneRRSummary from './standaloneRRSummary';
import ScanItemAsset from './ScanItemAsset';
import { restletGET } from '../../../apis/RestLetAPI';

function StandaloneRRForm() {
  const [inputs, setInputs] = useState({
    step: 1,
    customerText: '',
    receivedFrom: '',
    location: '103',
    locationText: '02 Golden',
    address: '',
    carrier: '',
    proNumber: '',
    containers: '',
    freightTerms: '1',
    remarks: '',
    inspectedByText: '',
    inspectedBy: '92125',
    email: '',
    scanAssetText: '',
    containerId: '',
    errorMessage: '',
    images: []
  });

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    restletGET({ type: 'getReturnableContainers' })
      .then((response) => {
        if (response.status === 200) {
          const { data } = response;
          console.log('response.data', data);
          if (data.success) {
            const containers = data.details.reduce(
              (obj, cur) => ({
                ...obj,
                [cur['Internal ID']]: {
                  id: cur['Internal ID'],
                  name: cur['Container Return Item'],
                  displayName: cur['Display Name'],
                  quantity: '',
                  assets: []
                }
              }),
              {}
            );
            setInputs((values) => ({ ...values, containers }));
          }
        }
      })
      .catch((error) => {
        console.log('getReturnableContainers error', error);
      });
  }, []);

  const nextStep = () => {
    const { step } = inputs;
    if ([2, 3].includes(step)) {
      let scanAsset = false;
      for (const [key, value] of Object.entries(inputs.containers)) {
        if (value.assets.length < Number(value.quantity)) {
          scanAsset = true;
          console.log('before updates inputs ' + key, {
            inputs,
            containerId: inputs.containerId,
            value
          });
          inputs.containerId = key;
          setInputs((values) => ({ ...values, containerId: key }));
          console.log('after updates inputs ', inputs);
          break;
        }
      }
      setInputs((values) => ({ ...values, step: scanAsset ? -1 : 4 }));
    } else if (step === -1) setInputs((values) => ({ ...values, step: 3 }));
    else setInputs((values) => ({ ...values, step: step + 1 }));
  };

  const prevStep = () => {
    const { step } = inputs;
    console.log('step', step);
    setInputs((values) => ({ ...values, step: step === 4 ? 2 : step - 1 }));
  };

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    if (name.startsWith('containers')) {
      const [key, id, attr] = name.split('-');
      inputs[key][id][attr] =
        attr === 'assets' ? inputs[key][id][attr].concat(value) : Number(value);
      setInputs((values) => ({ ...values, [key]: values[key] }));
    } else if (name === 'images') {
      inputs.images = inputs.images.concat(value);
      setInputs((values) => ({ ...values, images: inputs.images }));
    } else {
      setInputs((values) => ({ ...values, [name]: value }));
    }
  };

  switch (inputs.step) {
    case -1:
      return <RefreshElement nextStep={nextStep} />;
    case 1:
      return <CustomerInfo nextStep={nextStep} handleChange={handleChange} inputs={inputs} />;
    case 2:
      return (
        <ContainerSelection
          prevStep={prevStep}
          nextStep={nextStep}
          handleChange={handleChange}
          inputs={inputs}
        />
      );
    case 3:
      return (
        <ScanItemAsset
          prevStep={prevStep}
          nextStep={nextStep}
          handleChange={handleChange}
          inputs={inputs}
        />
      );
    case 4:
      return (
        <StandaloneRRSummary
          prevStep={prevStep}
          nextStep={nextStep}
          handleChange={handleChange}
          inputs={inputs}
        />
      );
    default:
      return <></>;
  }
}

function RefreshElement(props) {
  return props.nextStep();
}

export default StandaloneRRForm;
