import React, { useState } from 'react';
import AssetDetails from './AssetDetails';
import ScanAsset from './ScanAsset';
import RecentRecords from '../RecentRecords';
function AssetTrackingForm() {
  const [inputs, setInputs] = useState({
    step: 1,
    scanAssetText: '',
    tagId: '',
    productText: '',
    manufacturerSerialNumber: '',
    assetType: '',
    assetTypes: '',
    customer: '',
    manufacturerDate: '',
    errorMessage: '',
    submitForm: false
  });

  const nextStep = () => {
    const { step } = inputs;
    setInputs((values) => ({ ...values, step: step + 1 }));
  };

  const prevStep = () => {
    const { step } = inputs;
    setInputs((values) => ({ ...values, step: step - 1 }));
  };

  const handleChange = (event) => {
    console.log('handleChange', event);
    setInputs((values) => ({ ...values, [event.target.name]: event.target.value }));
  };

  switch (inputs.step) {
    case 1:
      return (
        <>
          <ScanAsset nextStep={nextStep} handleChange={handleChange} inputs={inputs} />
          <RecentRecords name={'Recent Asset'} type={'getRecentAssets'} />
        </>
      );
    case 2:
      return (
        <AssetDetails
          nextStep={nextStep}
          prevStep={prevStep}
          handleChange={handleChange}
          inputs={inputs}
        />
      );
  }
}

export default AssetTrackingForm;
