import React, { useState } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import QrReader from 'react-qr-scanner';

const QRCodeScan = (props) => {
  const [QRCodeScanShow, setQRCodeScanShow] = useState(false),
    handleScan = (data) => {
      console.log('scan', data);
      if (data?.text) {
        props.inputs[props.fieldPath] = data.text;
        props.handleChange({
          target: {
            name: props.fieldPath,
            value: data.text
          }
        });
        setQRCodeScanShow(false);
        setTimeout(() => {
          props?.submitBtnRef?.current?.click();
        }, 500);
      }
    },
    handleError = (error) => {
      console.log('error', error);
    };
  return (
    <>
      <Button variant="success" onClick={() => setQRCodeScanShow(true)} size="lg">
        {props.isSubmitting ? (
          <Spinner animation="border" size="sm" aria-hidden="true" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : (
          'Scan'
        )}
      </Button>
      <Modal
        show={QRCodeScanShow}
        onHide={() => setQRCodeScanShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Modal.Body>
          <QrReader
            delay={1000}
            style={{
              textAlign: 'center',
              height: '100%',
              width: '100%',
              objectFit: 'fill'
            }}
            onError={handleError}
            onScan={handleScan}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setQRCodeScanShow(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default QRCodeScan;
