import { Row, Container } from 'react-bootstrap';
import ActionCard from '../../components/ActionCard';
import { LuPenSquare } from 'react-icons/lu';
import { MdOutlineLocalShipping } from 'react-icons/md';
import { TbReportSearch } from 'react-icons/tb';
import { BsCardChecklist } from 'react-icons/bs';
import RecentRecords from '../../components/RecentRecords';
import MenuSideBar from '../../components/MenuSideBar';
import { Link } from 'react-router-dom';

function HomePage() {
  return (
    <Container>
      <MenuSideBar />
      <Row xs={1} md={2} className="m-4">
        <Link to={'./assetcreation'}>
          <ActionCard logo={<LuPenSquare />} text={'Asset Creation'} />
        </Link>
        <Link to={'./outbound'}>
          <ActionCard logo={<MdOutlineLocalShipping />} text={'Outbound'} />
        </Link>
        <Link to={'./receivingreport'}>
          <ActionCard logo={<BsCardChecklist />} text={'Receiving Report'} />
        </Link>
        <Link to={'./assettracking'}>
          <ActionCard logo={<TbReportSearch />} text={'Asset Tracking'} />
        </Link>
      </Row>
      <RecentRecords name={'Recent Records'} type={'getRecentRecords'} />
    </Container>
  );
}

export default HomePage;
