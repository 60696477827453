import React, { useRef } from 'react';
import { Alert, Form, InputGroup, Row, Col, Button } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { restletGET } from '../../apis/RestLetAPI';
import dayJS from 'dayjs';
import QRCodeScan from '../QRCodeScan';
function ScanAsset(props) {
  const getAsset = (event) => {
    try {
      restletGET({ type: 'getAsset', assetId: props.inputs.scanAssetText })
        .then((response) => {
          if (response.status === 200) {
            const {
              data,
              data: { details }
            } = response;
            console.log('getAsset data', data);
            props.handleChange({ target: { name: 'errorMessage', value: '' } });
            props.handleChange({ target: { name: 'tagId', value: details['Tag ID'] } });
            props.handleChange({ target: { name: 'productText', value: details['Product'] } });
            props.handleChange({
              target: { name: 'manufacturerSerialNumber', value: details['Manufacturer Serial No'] }
            });
            props.handleChange({
              target: {
                name: 'manufacturerDate',
                value: dayJS(details['Date of Manufacture']).format('YYYY-MM-DD')
              }
            });
            props.handleChange({ target: { name: 'assetType', value: details['Asset Type'] } });
            props.handleChange({ target: { name: 'customer', value: details['customer'] } });
            props.handleChange({ target: { name: 'assetId', value: details['Internal ID'] } });
            props.handleChange({ target: { name: 'scanAssetText', value: '' } });
            props.nextStep(event);
          }
        })
        .catch((error) => {
          console.log(error);
          props.handleChange({ target: { name: 'errorMessage', value: error.message } });
        });
      event.preventDefault();
      event.stopPropagation();
    } catch (e) {
      console.log('error in getAsset', e);
    }
  };
  console.log('scanasset props', props);
  const submitBtnRef = useRef();
  return (
    <Form onSubmit={getAsset}>
      {props.inputs.errorMessage ? (
        <Row className="mb-6">
          <Alert
            variant="danger"
            onClose={() => props.handleChange({ target: { name: 'errorMessage', value: '' } })}
            dismissible>
            <Alert.Heading>You got an error!</Alert.Heading>
            <p>{props.inputs.errorMessage}</p>
          </Alert>
        </Row>
      ) : (
        <></>
      )}
      <Row className="mb-6">
        <Form.Group as={Col} controlId="scanAsset">
          <InputGroup className="mb-3">
            <Form.Control
              type="text"
              defaultValue={props.inputs.scanAssetText}
              name="scanAssetText"
              placeholder="Scan Tag ID or Manufacturer Serial Number"
              required
              onChange={props.handleChange}
            />
            <Button id="submitBtn" ref={submitBtnRef} type="submit" variant="outline-secondary">
              <FaSearch />
            </Button>
          </InputGroup>
        </Form.Group>
      </Row>
      <Row className="mb-2 ">
        <Form.Group as={Col}>
          <div className="d-grid gap-2">
            <QRCodeScan
              inputs={props.inputs}
              fieldPath="scanAssetText"
              submitBtnRef={submitBtnRef}
              handleChange={props.handleChange}
            />
          </div>
        </Form.Group>
      </Row>
    </Form>
  );
}

export default ScanAsset;
