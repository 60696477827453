import { Button, Container, Row } from 'react-bootstrap';
import MenuSideBar from '../../components/MenuSideBar';
import ActionCard from '../../components/ActionCard';
import { LuPenSquare } from 'react-icons/lu';
import { MdOutlineLocalShipping } from 'react-icons/md';
import React, { useState } from 'react';
import StandaloneRRForm from '../../components/Inbound/StandaloneRR/StandaloneRRForm';
import RmaRRForm from '../../components/Inbound/RmaRR/RmaRRForm';
function ReceivingReport() {
  const [rrType, setType] = useState(null);
  switch (rrType) {
    case 'standalone':
      return (
        <Container>
          <MenuSideBar />
          <Row xs={1} md={1} className="m-4">
            <StandaloneRRForm />
          </Row>
        </Container>
      );
    case 'rma':
      return (
        <Container>
          <MenuSideBar />
          <RmaRRForm />
        </Container>
      );
    default:
      return (
        <Container>
          <MenuSideBar />
          <Row xs={1} md={2} className="m-4">
            <Button
              variant="link"
              onClick={() => {
                setType('standalone');
              }}>
              <ActionCard logo={<LuPenSquare />} text={'Receiving Report'} />
            </Button>
            <Button
              variant="link"
              onClick={() => {
                setType('rma');
              }}>
              <ActionCard logo={<MdOutlineLocalShipping />} text={'RMA'} />
            </Button>
          </Row>
        </Container>
      );
  }
}

export default ReceivingReport;
