import React, { useState } from 'react';
import { Form, Row, Col, Button, Table, Alert, InputGroup, Spinner } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { restletGET } from '../../../apis/RestLetAPI';

function SalesOrderSearch(props) {
  const [gettingSO, setGettingSO] = useState(false);
  const getSalesOrderLines = (e) => {
    e.preventDefault();
    e.stopPropagation();
    props.inputs.currentSoId = e.nativeEvent?.submitter?.id;
    props.handleChange({
      target: { name: 'currentSoId', value: e.nativeEvent?.submitter?.id }
    });
    // props.nextStep(e);
    restletGET({
      type: 'getOrderDetails',
      filters: `[["internalidnumber","equalto",${props.inputs.currentSoId}]]`
    })
      .then((response) => {
        if (response.status === 200) {
          const { data } = response;
          console.log('response.data', data);
          if (data.success) {
            const items = data.details.reduce(
              (obj, cur) => ({
                ...obj,
                [cur['Line ID']]: {
                  soNumber: cur['SO Number'],
                  id: cur['Item Internal ID'],
                  name: cur['Item'],
                  displayName: cur['Display Name'],
                  quantity: cur['Quantity'],
                  processedQuantity: cur['Processed Quantity'],
                  isLotSerial: cur['Is Lot'],
                  unit: cur['Units'].toLowerCase(),
                  assets: [],
                  lineId: cur['Line ID'],
                  whseLoc: cur['Whse Loc'],
                  locationId: cur['Location ID'],
                  inventoryDetails: [],
                  sessionProcessedQuantity: 0
                }
              }),
              {}
            );
            props.handleChange({ target: { name: 'items', value: items } });
          }
        }
      })
      .catch((error) => {
        console.log('getOrderDetails error', error);
      });
    props.nextStep(e);
  };
  const getSalesOrders = () => {
    setGettingSO(true);
    // const filters = [['location', 'anyof', '10']];
    const filters = [];
    if (props.inputs.customerText)
      filters.push(['customer.entityid', 'contains', props.inputs.customerText]);
    // eslint-disable-next-line react/prop-types
    restletGET({ type: 'getOpenSalesOrders', filters: JSON.stringify(filters) })
      .then((response) => {
        if (response.status === 200) {
          const { data } = response;
          console.log('response.data', data);
          if (data.success) {
            const salesOrders = data.details.map((so) => {
              return {
                id: so['Internal ID'],
                number: so['SO Number'],
                date: so['Date'],
                customer: so['Customer'],
                location: so['Location Internal ID']
              };
            });
            props.handleChange({ target: { name: 'salesOrders', value: salesOrders } });
          }
        }
      })
      .catch((error) => {
        console.log('getOpenSalesOrders error', error);
      })
      .finally(() => {
        setGettingSO(false);
      });
  };
  return (
    <Form onSubmit={getSalesOrderLines}>
      {props.inputs.errorMessage ? (
        <Row className="mb-6">
          <Alert
            variant="danger"
            onClose={() => props.handleChange({ target: { name: 'errorMessage', value: '' } })}
            dismissible>
            <Alert.Heading>You got an error!</Alert.Heading>
            <p>{props.inputs.errorMessage}</p>
          </Alert>
        </Row>
      ) : (
        <></>
      )}

      <Row className="mb-2">
        <InputGroup className="mb-3">
          <Form.Control
            type="text"
            name="customerText"
            placeholder="Enter Customer Name"
            onChange={props.handleChange}
            value={props.inputs.customerText}
          />
          <Button variant="outline-secondary" onClick={getSalesOrders}>
            <FaSearch />
          </Button>
        </InputGroup>
      </Row>
      {gettingSO ? (
        <Row className="mb-2">
          <Form.Group as={Col}>
            <Spinner animation="border" role="status" variant="primary">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </Form.Group>
        </Row>
      ) : (
        <></>
      )}
      <Row className="mb-2">
        <Form.Group as={Col} controlId="containerList">
          {!gettingSO && props.inputs.salesOrders ? (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Sales Order</th>
                  <th>Customer</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {props.inputs.salesOrders.map((so, i) => (
                  <tr key={i}>
                    <td>
                      <Button id={so.id} type="submit" variant="link">
                        {so.number}
                      </Button>
                    </td>
                    <td>{so.customer}</td>
                    <td>{so.date}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <></>
          )}
        </Form.Group>
      </Row>
    </Form>
  );
}

export default SalesOrderSearch;
