import { Container, Row } from 'react-bootstrap';

import MenuSideBar from '../../components/MenuSideBar';
import AssetTrackingForm from '../../components/AssetTracking/AssetTrackingForm';

function AssetTracking() {
  return (
    <Container>
      <MenuSideBar />
      <Row xs={1} md={1} className="m-4">
        <AssetTrackingForm />
      </Row>
    </Container>
  );
}

export default AssetTracking;
