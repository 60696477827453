import React, { useCallback, useState } from 'react';
import { Col, Form, Row, Modal, Button, Alert } from 'react-bootstrap';
import Webcam from 'react-webcam';
const ImageCapture = (props) => {
  const FACING_MODE_USER = 'user',
    FACING_MODE_ENVIRONMENT = 'environment',
    [facingMode, setFacingMode] = useState(FACING_MODE_USER),
    switchCamera = useCallback(() => {
      setFacingMode((prevState) =>
        prevState === FACING_MODE_USER ? FACING_MODE_ENVIRONMENT : FACING_MODE_USER
      );
    }, []),
    videoConstraints = {
      width: { min: 1000 },
      height: { min: 1000 },
      facingMode: FACING_MODE_USER
    },
    [imageCaptureShow, setImageCaptureShow] = useState(false),
    [showSucess, setShowSuccess] = useState(false),
    updateSuccessDisplay = () => {
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
      }, 1000);
    };

  console.log('ImageCapture', { facingMode, imageCaptureShow });
  return (
    <>
      <Button variant="success" onClick={() => setImageCaptureShow(true)} size="lg">
        Add Images
      </Button>
      <Modal
        show={imageCaptureShow}
        onHide={() => setImageCaptureShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered>
        <Alert show={showSucess} variant="success" dismissible>
          <Alert.Heading>Image Captured!</Alert.Heading>
        </Alert>
        <Modal.Body>
          <Webcam
            audio={false}
            screenshotFormat="image/jpeg"
            minScreenshotHeight={1000}
            minScreenshotWidth={1000}
            width={500}
            height={500}
            style={{
              textAlign: 'center',
              height: '100%',
              width: '100%',
              objectFit: 'fill'
            }}
            videoConstraints={videoConstraints}>
            {({ getScreenshot }) => (
              <Row className="mb-2">
                <Form.Group as={Col}>
                  <div className="d-grid gap-2">
                    <Button variant="secondary" onClick={switchCamera}>
                      Switch Camera
                    </Button>
                  </div>
                </Form.Group>
                <Form.Group as={Col}>
                  <div className="d-grid gap-2">
                    <Button
                      variant="success"
                      onClick={() => {
                        const imageSrc = getScreenshot();
                        props.handleChange({
                          target: {
                            name: 'images',
                            value: imageSrc
                          }
                        });
                        console.log('imageSrc', imageSrc);
                        updateSuccessDisplay();
                      }}>
                      Capture photo
                    </Button>
                  </div>
                </Form.Group>
              </Row>
            )}
          </Webcam>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setImageCaptureShow(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ImageCapture;
