import React, { useState } from 'react';
import { Form, Row, Col, Button, Table, Spinner, Alert } from 'react-bootstrap';
import ImageCapture from './ImageCapture';
import ImagePanel from './ImagesPanel';
import { restletPOST } from '../../../apis/RestLetAPI';
import { Link } from 'react-router-dom';

function StandaloneRRSummary(props) {
  console.log('StandaloneRRSummary inputs', props.inputs);
  const [result, setResult] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const createReceivingReport = (e) => {
    setSubmitting(true);
    e.preventDefault();
    e.stopPropagation();
    for (const [key, value] of Object.entries(props.inputs.containers)) {
      if (Number(value.quantity) <= 0) delete props.inputs.containers[key];
    }
    const data = {
      type: 'createReceivingReport',
      data: props.inputs
    };
    console.log('updateasset data', data);
    restletPOST({ data })
      .then((response) => {
        if (response.status === 200) {
          console.log('response', response.data);
          const data = response.data;
          if (response.data.success)
            setResult(`Receiving Report #${data.details} has been created (id:${data.details})`);
          else setResult(`Error when creating Receiving Report`);

          console.log('result', result);
        }
      })
      .catch((error) => {
        console.log(error);
        setResult(error.message);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  return (
    <>
      {result ? (
        <>
          {' '}
          <Row className="mb-6">
            <Alert
              key="result"
              variant={result.toLowerCase().includes('error') ? 'danger' : 'success'}>
              {result}
            </Alert>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col}>
              <div className="d-grid gap-2">
                <Link to={'/'}>
                  <Button variant="primary" size="lg">
                    Back To Dashboard
                  </Button>
                </Link>
              </div>
            </Form.Group>
          </Row>
        </>
      ) : (
        <Form onSubmit={createReceivingReport}>
          <Row className="mb-2">
            <Form.Group as={Col} controlId="containerList">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Quantity</th>
                    <th>Container Type</th>
                    {/*<th>ID</th>*/}
                  </tr>
                </thead>
                <tbody>
                  {props.inputs.containers ? (
                    Object.values(props.inputs.containers)
                      .filter((c) => Number(c.quantity) > 0)
                      .map((container, i) => (
                        <tr key={i}>
                          <td>{container.quantity || 0}</td>
                          <td>{container.displayName}</td>
                          {/*<td className=".d-none">{container.id}</td>*/}
                        </tr>
                      ))
                  ) : (
                    <option value="" key="loading">
                      Loading...
                    </option>
                  )}
                </tbody>
              </Table>
            </Form.Group>
          </Row>
          <Row className="mb-1">
            <Form.Group as={Col} controlId="attachments">
              <ImageCapture handleChange={props.handleChange} inputs={props.inputs} />
            </Form.Group>
            <Form.Group as={Col} controlId="showAttachments">
              <ImagePanel inputs={props.inputs} />
            </Form.Group>
          </Row>

          <Row className="mb-1">
            <Form.Group as={Col} controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                defaultValue={props.inputs.email}
                name="email"
                required
                onChange={props.handleChange}
              />
            </Form.Group>
          </Row>
          <Row className="mb-1">
            <Form.Group as={Col} controlId="inspectedBy">
              <Form.Label>Inspected/Received By</Form.Label>
              <Form.Control
                type="text"
                defaultValue={props.inputs.inspectedByText}
                name="inspectedByText"
                required
                onChange={props.handleChange}
              />
            </Form.Group>
          </Row>
          <Row className="mb-2 ">
            <Form.Group as={Col}>
              <div className="d-grid gap-2">
                <Button variant="secondary" size="lg" onClick={props.prevStep}>
                  Back
                </Button>
              </div>
            </Form.Group>
            <Form.Group as={Col}>
              <div className="d-grid gap-2">
                <Button disabled={isSubmitting} type="submit" variant="primary" size="lg">
                  {isSubmitting ? (
                    <Spinner animation="border" size="sm" aria-hidden="true" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : (
                    'Submit'
                  )}
                </Button>
              </div>
            </Form.Group>
          </Row>
        </Form>
      )}
    </>
  );
}

export default StandaloneRRSummary;
