// import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomePage from './pages/Home/HomePage';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AssetTracking from './pages/AssetTracking/AssetTracking';
import AssetCreation from './pages/AssetCreation/AssetCreation';
import ReceivingReport from './pages/ReceivingReport/ReceivingReport';
import Outbound from './pages/Outbound/Outbound';
function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/assettracking" element={<AssetTracking />} />
          <Route path="/assetcreation" element={<AssetCreation />} />
          <Route path="/receivingreport" element={<ReceivingReport />} />
          <Route path="/outbound" element={<Outbound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
