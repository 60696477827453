import React from 'react';
import { Form, Row, Col, Button, Alert } from 'react-bootstrap';

function LineQuantitySelection(props) {
  console.log('LineQuantitySelection inputs', props.inputs);

  return (
    <>
      <h3>Line Quantity</h3>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          props.nextStep(e);
        }}>
        {props.inputs.errorMessage ? (
          <Row className="mb-6">
            <Alert
              variant="danger"
              onClose={() => props.handleChange({ target: { name: 'errorMessage', value: '' } })}
              dismissible>
              <Alert.Heading>You got an error!</Alert.Heading>
              <p>{props.inputs.errorMessage}</p>
            </Alert>
          </Row>
        ) : (
          <></>
        )}
        <Row className="mb-6">
          <Form.Group as={Col} controlId="lineQuantity">
            <Form.Control
              type="number"
              name="processingQuantity"
              placeholder="Enter Quantity"
              onChange={props.handleChange}
              defaultValue={props.inputs.processingQuantity}
              required
            />
          </Form.Group>
        </Row>
        <Row className="mb-2">
          <Form.Group as={Col}>
            <div className="d-grid gap-2"></div>
          </Form.Group>
        </Row>
        <Row className="mb-2 ">
          <Form.Group as={Col}>
            <div className="d-grid gap-2">
              <Button variant="secondary" size="lg" onClick={props.prevStep}>
                Back
              </Button>
            </div>
          </Form.Group>
          <Form.Group as={Col}>
            <div className="d-grid gap-2">
              <Button type="submit" variant="primary" size="lg">
                Next
              </Button>
            </div>
          </Form.Group>
        </Row>
      </Form>
    </>
  );
}

export default LineQuantitySelection;
