/* eslint-disable react/prop-types,react/jsx-key */
import Table from 'react-bootstrap/Table';
import { restletGET } from '../apis/RestLetAPI';
import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';

function RecentRecords(props) {
  const [recentRecords, setRecentRecords] = useState(null);
  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    restletGET({ type: props.type })
      .then((response) => {
        if (response.status === 200) {
          console.log('response.data', response.data);
          setRecentRecords(response.data.details);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <div>
      <h3>{props.name}</h3>
      {recentRecords ? (
        <Table responsive="md" striped>
          <thead>
            <tr key="-1">
              {Object.keys(recentRecords[0]).map((recentRec) => (
                // eslint-disable-next-line react/jsx-key
                <th>{recentRec}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {recentRecords.map((recentRec, i) => (
              <tr key={i}>
                {Object.keys(recentRecords[0]).map((key, j) => (
                  <td key={j}>{recentRec[key]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <Spinner animation="border" role="status" variant="primary">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
    </div>
  );
}

export default RecentRecords;
