import { Container, Form, Row, Col, Button, Spinner, Alert } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import dayJS from 'dayjs';
import MenuSideBar from '../../components/MenuSideBar';
import { restletGET, restletPOST } from '../../apis/RestLetAPI';
import { Link } from 'react-router-dom';

function AssetCreation() {
  const [result, setResult] = useState(null);
  const [assetType, setassetType] = useState(null);
  const [inputs, setInputs] = useState({});
  const [validated, setValidated] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    restletGET({ type: 'getAssetTypes' })
      .then((response) => {
        if (response.status === 200) {
          setassetType(response.data.details);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleChange = (event) => {
    const id = event.target.id;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [id]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);
    const form = event.currentTarget;
    console.log('inputs', inputs);
    console.log('isSubmitting', isSubmitting);
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    if (form.checkValidity() === true) {
      inputs.manufacturerDate = dayJS(inputs.manufacturerDate).format('MM/DD/YYYY');
      const data = {
        type: 'createAsset',
        data: inputs
      };
      console.log('data', data);
      restletPOST({ data })
        .then((response) => {
          if (response.status === 200) {
            const { data } = response;
            if (data.success)
              setResult(`Asset #${inputs.tagId} has been created (id:${data.details})`);
            else setResult(`Error when creating Asset #${inputs.tagId}: ${data.error.message}`);
            console.log('result', result);
          }
        })
        .catch((error) => {
          console.log(error);
          setResult(error.message);
        })
        .finally(() => {
          setSubmitting(false);
        });
    }

    setValidated(true);
  };
  return (
    <Container>
      <MenuSideBar />
      <Row xs={1} md={1} className="m-4">
        {result ? (
          <>
            <Row className="mb-6">
              <Alert
                key="result"
                variant={result.toLowerCase().includes('error') ? 'danger' : 'primary'}>
                {result}
              </Alert>
            </Row>
            <Row className="mb-2">
              <Form.Group as={Col}>
                <div className="d-grid gap-2">
                  <Link to={'/'}>
                    <Button variant="primary" size="lg">
                      Back To Dashboard
                    </Button>
                  </Link>
                </div>
              </Form.Group>
            </Row>
          </>
        ) : (
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row className="mb-2">
              <Form.Group as={Col} md="6" controlId="tagId">
                <Form.Label>Tag ID</Form.Label>
                <Form.Control
                  required
                  onChange={handleChange}
                  value={inputs.tagId || ''}
                  type="text"
                  placeholder="Enter Tag ID"
                />
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="product">
                <Form.Label>Product</Form.Label>
                <Form.Control
                  onChange={handleChange}
                  value={inputs.product || ''}
                  type="text"
                  placeholder="Enter Product"
                />
              </Form.Group>
            </Row>
            <Row className="mb-2">
              <Form.Group as={Col} md="6" controlId="serialNumber">
                <Form.Label>Manufacturer Serial No. </Form.Label>
                <Form.Control
                  onChange={handleChange}
                  value={inputs.serialNumber || ''}
                  type="text"
                  placeholder="Enter Serial Number"
                />
              </Form.Group>
              <Form.Group as={Col} md="6" controlId="assetType">
                <Form.Label>Asset Type</Form.Label>
                <Form.Select
                  onChange={handleChange}
                  value={inputs.assetType || ''}
                  aria-label="Asset Type">
                  {assetType ? (
                    [
                      <option value="" key="default">
                        Select
                      </option>
                    ].concat(
                      assetType.map((ast, i) => (
                        <option value={ast.value} key={i}>
                          {ast.name}
                        </option>
                      ))
                    )
                  ) : (
                    <option value="" key="loading">
                      Loading...
                    </option>
                  )}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row className="mb-2">
              <Form.Group as={Col} md="6" controlId="manufacturerDate">
                <Form.Label>Manufacturer Date</Form.Label>
                <Form.Control
                  onChange={handleChange}
                  value={inputs.manufacturerDate || ''}
                  type="date"
                  placeholder="Enter Manufacturer Date"
                />
              </Form.Group>
            </Row>

            <Row className="mb-2">
              <Button disabled={isSubmitting} type="submit">
                {isSubmitting ? (
                  <Spinner animation="border" size="sm" aria-hidden="true" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : (
                  'Submit form'
                )}
              </Button>
            </Row>
          </Form>
        )}
      </Row>
    </Container>
  );
}

export default AssetCreation;
