import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import ActionCard from './ActionCard';
import { GiHamburgerMenu } from 'react-icons/gi';
import { LuPenSquare } from 'react-icons/lu';
import { MdOutlineLocalShipping } from 'react-icons/md';
import { BsCardChecklist } from 'react-icons/bs';
import { TbReportSearch } from 'react-icons/tb';
import { FaMobileAlt } from 'react-icons/fa';
import { IoStatsChartOutline } from 'react-icons/io5';
import { FaRegQuestionCircle } from 'react-icons/fa';
import { IoIosLogOut } from 'react-icons/io';
import { Link } from 'react-router-dom';
function MenuSideBar() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button className="float-start" variant="primary" onClick={handleShow}>
        <GiHamburgerMenu />
      </Button>

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>ClearTech Industries</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Link to={'/'}>
            <ActionCard logo=<IoStatsChartOutline /> text={'Dashboard'} />
          </Link>
          <Link to={'/assetcreation'}>
            <ActionCard logo={<LuPenSquare />} text={'Asset Creation'} />
          </Link>
          <Link to={'/assettracking'}>
            <ActionCard logo={<TbReportSearch />} text={'Asset Tracking'} />
          </Link>
          <Link to={'/receivingreport'}>
            <ActionCard logo={<BsCardChecklist />} text={'Receiving Report'} />
          </Link>
          <Link to={'/outbound'}>
            <ActionCard logo={<MdOutlineLocalShipping />} text={'Outbound'} />
          </Link>
          <ActionCard logo={<FaMobileAlt />} text={'Mobile'} />
          <ActionCard logo={<FaRegQuestionCircle />} text={'Help'} />
          <ActionCard logo={<IoIosLogOut />} text={'Log Out'} />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default MenuSideBar;
