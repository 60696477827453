import { Row, Container, Button } from 'react-bootstrap';
import ActionCard from '../../components/ActionCard';
import { LuPenSquare } from 'react-icons/lu';
import { MdOutlineLocalShipping } from 'react-icons/md';
import MenuSideBar from '../../components/MenuSideBar';
import React, { useState } from 'react';
import SalesOrderForm from '../../components/Outbound/SalesOrder/SalesOrderForm';

function Outbound() {
  const [outboundType, setType] = useState(null);
  switch (outboundType) {
    case 'salesorder':
      return (
        <Container>
          <MenuSideBar />
          <Row xs={1} md={1} className="m-4">
            <SalesOrderForm />
          </Row>
        </Container>
      );
    case 'transferorder':
      return (
        <Container>
          <MenuSideBar />
        </Container>
      );
    default:
      return (
        <Container>
          <MenuSideBar />
          <Row xs={1} md={2} className="m-4">
            <Button
              variant="link"
              onClick={() => {
                setType('salesorder');
              }}>
              <ActionCard logo={<LuPenSquare />} text={'Sales Order'} />
            </Button>
            <Button
              variant="link"
              onClick={() => {
                setType('transferorder');
              }}>
              <ActionCard logo={<MdOutlineLocalShipping />} text={'Transfer Order'} />
            </Button>
          </Row>
        </Container>
      );
  }
}

export default Outbound;
