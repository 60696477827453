import React, { useState } from 'react';
function RmaRRForm() {
  const [inputs, setInputs] = useState({
    step: 'selectType',
    rrType: ''
  });

  console.log('typeof', typeof setInputs);

  // const nextStep = () => {
  //   const { step } = inputs;
  //   setInputs((values) => ({ ...values, step: step + 1 }));
  // };
  //
  // const prevStep = () => {
  //   const { step } = inputs;
  //   setInputs((values) => ({ ...values, step: step - 1 }));
  // };
  //
  // const handleChange = (event) => {
  //   setInputs((values) => ({ ...values, [event.target.name]: event.target.value }));
  // };

  switch (inputs.step) {
    case 1:
      return <p>step1</p>;
    case 2:
      return <p>step2</p>;
    default:
      return <></>;
  }
}

export default RmaRRForm;
