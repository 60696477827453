import { restletRequest, createHeader } from './ResLetRequest';

export const restletGET = (props) => {
  return restletRequest.get('', {
    params: props,
    headers: {
      Authorization: createHeader('GET', props)
    }
  });
};
export const restletPOST = (props) => {
  return restletRequest.post('', props.data, {
    headers: {
      Authorization: createHeader('POST')
    }
  });
};

const processes = {
  restletGET,
  restletPOST
};
export default processes;
