import React, { useState } from 'react';
import { Form, Row, Col, Button, Table, Spinner, Alert } from 'react-bootstrap';

function LineSelection(props) {
  const [showFinishedLines, setShowFinishedLines] = useState(false);
  // const submitBtnRef = useRef();
  console.log('LineSelectionSearch inputs', props.inputs);

  const goToSummary = () => {
    props.handleChange({ target: { name: 'targetStep', value: props.STEPS.SUMMARY } });
    props.handleChange({ target: { name: 'step', value: -1 } });
  };

  return (
    <>
      <h3>Sales Order Lines</h3>

      <Form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          props.inputs.currentLineId = e.nativeEvent?.submitter?.id;
          props.handleChange({
            target: { name: 'currentLineId', value: e.nativeEvent?.submitter?.id }
          });
          props.nextStep(e);
        }}>
        {props.inputs.errorMessage ? (
          <Row className="mb-6">
            <Alert
              variant="danger"
              onClose={() => props.handleChange({ target: { name: 'errorMessage', value: '' } })}
              dismissible>
              <Alert.Heading>You got an error!</Alert.Heading>
              <p>{props.inputs.errorMessage}</p>
            </Alert>
          </Row>
        ) : (
          <></>
        )}
        {props.inputs.items ? (
          <>
            <Row className="mb-2">
              <Form.Group as={Col} controlId="itemList">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Item</th>
                      <th>Display Name</th>
                      <th>Quantity</th>
                      <th>Unfinished Quantity</th>
                      <th>WHSE LOC</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.values(props.inputs.items)
                      .filter((item) => Number(item.processedQuantity) < Number(item.quantity))
                      .map((item, i) => (
                        <tr key={i}>
                          <td>
                            <Button id={item.lineId} type="submit" variant="link">
                              {item.name}
                            </Button>
                          </td>
                          <td>{item.displayName}</td>
                          <td>{item.quantity}</td>
                          <td>{Number(item.quantity) - Number(item.processedQuantity)}</td>
                          <td>{item.whseLoc}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Form.Group>
            </Row>
          </>
        ) : (
          <Row className="mb-2">
            <Form.Group as={Col}>
              <Spinner animation="border" role="status" variant="primary">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </Form.Group>
          </Row>
        )}

        {Object.values(props.inputs.items).filter(
          (item) => Number(item.processedQuantity) >= Number(item.quantity)
        ).length ? (
          <Row className="mb-2">
            <Form.Group as={Col}>
              <Button
                variant="success"
                onClick={() => {
                  setShowFinishedLines(!showFinishedLines);
                }}>
                {showFinishedLines ? 'Hide Finished Lines' : 'Show Finished Lines'}
              </Button>
            </Form.Group>
          </Row>
        ) : (
          <></>
        )}
        {showFinishedLines && props.inputs.items ? (
          <Row className="mb-2">
            <Form.Group as={Col} controlId="itemList">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Item</th>
                    <th>Display Name</th>
                    <th>Quantity</th>
                    <th>WHSE LOC</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.values(props.inputs.items)
                    .filter((item) => Number(item.processedQuantity) >= Number(item.quantity))
                    .map((item, i) => (
                      <tr key={i}>
                        <td>{item.name}</td>
                        <td>{item.displayName}</td>
                        <td>{item.quantity}</td>
                        <td>{item.whseLoc}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </Form.Group>
          </Row>
        ) : (
          <></>
        )}
        <Row className="mb-2">
          <Form.Group as={Col}>
            <div className="d-grid gap-2">
              <Button variant="secondary" size="lg" onClick={props.prevStep}>
                Back
              </Button>
            </div>
          </Form.Group>
          <Form.Group as={Col}>
            <div className="d-grid gap-2">
              {Object.values(props.inputs.items).filter(
                (item) => Number(item.sessionProcessedQuantity) > 0
              ).length ? (
                <>
                  <Button onClick={goToSummary} variant="primary" size="lg">
                    Go To Summary
                  </Button>
                </>
              ) : (
                <></>
              )}
            </div>
          </Form.Group>
        </Row>
      </Form>
    </>
  );
}

export default LineSelection;
