import React from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
function CustomerInfo(props) {
  return (
    <>
      <h3>Customer Information</h3>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          props.nextStep(e);
        }}>
        <Row className="mb-6">
          <Form.Group as={Col} controlId="customerText">
            <Form.Label>Customer</Form.Label>
            <Form.Control
              type="text"
              defaultValue={props.inputs.customerText}
              name="customerText"
              onChange={props.handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="location">
            <Form.Label>Location</Form.Label>
            <Form.Control
              type="text"
              defaultValue={props.inputs.locationText}
              name="locationText"
              onChange={props.handleChange}
            />
          </Form.Group>
        </Row>
        <Row className="mb-6">
          <Form.Group as={Col} controlId="receivedFrom">
            <Form.Label>Received From</Form.Label>
            <Form.Control
              type="text"
              defaultValue={props.inputs.receivedFrom}
              name="receivedFrom"
              onChange={props.handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="carrier">
            <Form.Label>Carrier</Form.Label>
            <Form.Control
              type="text"
              defaultValue={props.inputs.carrier}
              name="carrier"
              onChange={props.handleChange}
            />
          </Form.Group>
        </Row>
        <Row className="mb-6">
          <Form.Group as={Col} controlId="proNumber">
            <Form.Label>Pro #</Form.Label>
            <Form.Control
              type="text"
              defaultValue={props.inputs.proNumber}
              name="proNumber"
              required
              onChange={props.handleChange}
            />
          </Form.Group>
          <Form.Group as={Col} controlId="freightTerms">
            <Form.Label>Freight Terms</Form.Label>
            <Form.Select
              type="text"
              defaultValue={props.inputs.freightTerms}
              name="freightTerms"
              required
              onChange={props.handleChange}
              aria-label="Freight Terms">
              <option value="1" key="0">
                Prepaid
              </option>
              <option value="2" key="1">
                Collect
              </option>
            </Form.Select>
          </Form.Group>
        </Row>
        <Row className="mb-1">
          <Form.Group as={Col} controlId="address">
            <Form.Label>Address</Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              defaultValue={props.inputs.address}
              name="address"
              onChange={props.handleChange}></Form.Control>
          </Form.Group>
        </Row>
        <Row className="mb-2 ">
          <Form.Group as={Col}></Form.Group>
          <Form.Group as={Col}>
            <div className="d-grid gap-2">
              <Button type="submit" variant="primary" size="lg">
                Next
              </Button>
            </div>
          </Form.Group>
        </Row>
      </Form>
    </>
  );
}

export default CustomerInfo;
