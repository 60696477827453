import React, { useState } from 'react';
import { Form, Row, Button, Col, Alert, Spinner } from 'react-bootstrap';
import { restletPOST } from '../../apis/RestLetAPI';
import { Link } from 'react-router-dom';

function AssetDetails(props) {
  const [result, setResult] = useState(null);
  const [isSubmitting, setSubmitting] = useState(false);
  const updateAsset = (e) => {
    setSubmitting(true);
    e.preventDefault();
    e.stopPropagation();
    const data = {
      type: 'updateAsset',
      data: {
        id: props.inputs?.assetId,
        product: props.inputs?.productText
      }
    };
    console.log('updateasset data', data);
    restletPOST({ data })
      .then((response) => {
        if (response.status === 200) {
          console.log('response', response.data);
          const data = response.data;
          if (response.data.success)
            setResult(`Asset #${props.inputs.tagId} has been created (id:${data.details})`);
          else setResult(`Error when creating Asset #${props.inputs.tagId}`);

          console.log('result', result);
        }
      })
      .catch((error) => {
        console.log(error);
        setResult(error.message);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  console.log('AssetDetails props values', props);
  return (
    <>
      {result ? (
        <>
          <Row className="mb-6">
            <Alert
              key="result"
              variant={result.toLowerCase().includes('error') ? 'danger' : 'success'}>
              {result}
            </Alert>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col}>
              <div className="d-grid gap-2">
                <Link to={'/'}>
                  <Button variant="primary" size="lg">
                    Back To Dashboard
                  </Button>
                </Link>
              </div>
            </Form.Group>
          </Row>
        </>
      ) : (
        <Form onSubmit={updateAsset}>
          <Row className="mb-2">
            <Form.Group as={Col} md="6" controlId="tagId">
              <Form.Label>Tag ID</Form.Label>
              <Form.Control
                type="text"
                disabled
                defaultValue={props.inputs.tagId}
                name="tagId"
                // onChange={props.handleChange}
              />
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="productText">
              <Form.Label>Product</Form.Label>
              <Form.Control
                type="text"
                defaultValue={props.inputs.productText}
                name="productText"
                onChange={props.handleChange}
              />
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col} md="6" controlId="manufacturerSerialNumber">
              <Form.Label>Manufacturer Serial Number</Form.Label>
              <Form.Control
                type="text"
                disabled
                defaultValue={props.inputs.manufacturerSerialNumber}
                name="manufacturerSerialNumber"
              />
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="assetType">
              <Form.Label>Asset Type</Form.Label>
              <Form.Control
                type="text"
                defaultValue={props.inputs.assetType || ''}
                name="assetType"
                disabled
              />
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col} md="6" controlId="customer">
              <Form.Label>Customer</Form.Label>
              <Form.Control
                type="text"
                defaultValue={props.inputs.customer}
                name="customer"
                disabled
                // required
                // onChange={props.handleChange}
              />
            </Form.Group>
            <Form.Group as={Col} md="6" controlId="manufacturerDate">
              <Form.Label>Manufacturer Date</Form.Label>
              <Form.Control
                type="date"
                defaultValue={props.inputs.manufacturerDate}
                name="manufacturerDate"
                disabled
                // required
                // onChange={props.handleChange}
              />
            </Form.Group>
          </Row>
          <Row className="mb-2">
            <Form.Group as={Col}>
              <div className="d-grid gap-2">
                <Button variant="secondary" size="lg" onClick={props.prevStep}>
                  Back
                </Button>
              </div>
            </Form.Group>
            <Form.Group as={Col}>
              <div className="d-grid gap-2">
                <Button disabled={isSubmitting} type="submit" variant="primary" size="lg">
                  {isSubmitting ? (
                    <Spinner animation="border" size="sm" aria-hidden="true" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </Spinner>
                  ) : (
                    'Update'
                  )}
                </Button>
              </div>
            </Form.Group>
          </Row>
        </Form>
      )}
    </>
  );
}

export default AssetDetails;
