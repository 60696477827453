import React, { useState } from 'react';
import SalesOrderSearch from './SalesOrderSearch';
import LineSelection from './LineSelection';
import LineQuantitySelection from './LineQuantitySelection';
import LineInventoryDetailSelection from './LineInventoryDetailSelection';
import LineAssetScan from './LineAssetScan';
import SalesOrderSummary from './SalesOrderSummary';

function SalesOrderForm() {
  const STEPS = {
    REFRESH: -1,
    SALES_ORDER_SEARCH: 1,
    LINE_SELECTION: 2,
    LINE_QUANTITY_SELECTION: 3,
    INVENTORY_DETAIL_SELECTION: 4,
    LINE_ASSET_SCAN: 5,
    SUMMARY: 6
  };
  const [inputs, setInputs] = useState({
    step: 1,
    processingQuantity: '',
    inventoryDetailQuantity: '',
    inventoryDetailText: '',
    assetQuantity: '',
    scanAssetText: '',
    currentLineId: '',
    customerText: 'Cenovus Energy Inc.',
    currentSoId: '',
    location: '',
    locationText: '02 Golden',
    salesOrders: '',
    //     [
    //   { id: 123, number: 'SO123', customer: 'Test Customer' },
    //   { id: 456, number: 'SO456', customer: 'Test Customer' },
    //   { id: 789, number: 'SO789', customer: 'Test Customer' }
    // ]
    items: '',
    // items: {
    //   1: {
    //     id: 6257,
    //     name: 'CPACPLUS45',
    //     displayName: 'Clearpac Plus, NSF® - 60 255kg returnable drum',
    //     unit: 'tote',
    //     quantity: 3,
    //     assets: [],
    //     isLotSerial: true,
    //     inventoryDetails: [],
    //     lineId: 1,
    //     whseLoc: 'test location',
    //     processedQuantity: 3,
    //     sessionProcessedQuantity: 3,
    //     locationId: 8
    //   },
    //   2: {
    //     id: 5469,
    //     name: 'PALLET01',
    //     displayName: 'Wooden Deposit Pallet',
    //     unit: 'tote',
    //     quantity: 1,
    //     assets: [],
    //     isLotSerial: false,
    //     inventoryDetails: [],
    //     lineId: 2,
    //     whseLoc: 'test location',
    //     processedQuantity: 1,
    //     sessionProcessedQuantity: 1,
    //     locationId: 8
    //   },
    //   3: {
    //     id: 5469,
    //     name: 'PALLET02',
    //     displayName: 'Wooden Deposit Pallets',
    //     unit: 'test',
    //     quantity: 2,
    //     assets: [],
    //     isLotSerial: false,
    //     inventoryDetails: [],
    //     lineId: 3,
    //     whseLoc: 'test location',
    //     processedQuantity: 0,
    //     sessionProcessedQuantity: 0
    //   }
    // },
    email: '',
    errorMessage: '',
    targetStep: '',
    waybillNo: '',
    driversName: ''
  });

  const nextStep = () => {
    const ASSET_UNITS = ['tote', 'cylinder'];
    const { step, items, currentLineId } = inputs;
    if (step === -1) setInputs((values) => ({ ...values, step: inputs.targetStep }));
    else if (step === STEPS.LINE_SELECTION) {
      const hasUnfinishedLines = !!Object.values(inputs.items).filter(
        (item) => Number(item.processedQuantity) < Number(item.quantity)
      ).length;
      if (!hasUnfinishedLines) {
        setInputs((values) => ({ ...values, step: STEPS.SUMMARY }));
        return;
      }
      const unfinishedQuantity =
        Number(inputs.items[currentLineId]?.quantity) -
        Number(inputs.items[currentLineId]?.processedQuantity);
      setInputs((values) => ({
        ...values,
        ...{
          processingQuantity: Number(unfinishedQuantity),
          step: step + 1
        }
      }));
    } else if (step === STEPS.LINE_QUANTITY_SELECTION) {
      const { unit, isLotSerial } = items[currentLineId];
      if (
        isLotSerial &&
        Number(inputs.inventoryDetailQuantity) < Number(inputs.processingQuantity)
      ) {
        setInputs((values) => ({ ...values, step: step + 1 }));
        return;
      } else if (ASSET_UNITS.includes(unit.toLowerCase())) {
        setInputs((values) => ({ ...values, step: STEPS.LINE_ASSET_SCAN }));
      } else completeLineProcess();
    } else if (step === STEPS.INVENTORY_DETAIL_SELECTION) {
      const { unit } = items[currentLineId];
      console.log('InventoryDetailSelection', inputs);
      if (Number(inputs.inventoryDetailQuantity) < Number(inputs.processingQuantity)) {
        setInputs((values) => ({
          ...values,
          ...{ targetStep: STEPS.INVENTORY_DETAIL_SELECTION, step: -1 }
        }));
      } else {
        if (ASSET_UNITS.includes(unit.toLowerCase())) {
          setInputs((values) => ({ ...values, step: step + 1 }));
        } else completeLineProcess();
      }
    } else if (step === STEPS.LINE_ASSET_SCAN) {
      if (Number(inputs.assetQuantity) < Number(inputs.processingQuantity)) {
        setInputs((values) => ({ ...values, ...{ targetStep: STEPS.LINE_ASSET_SCAN, step: -1 } }));
      } else completeLineProcess();
    } else setInputs((values) => ({ ...values, step: step + 1 }));
  };

  const completeLineProcess = () => {
    inputs.items[inputs.currentLineId].processedQuantity += Number(inputs.processingQuantity);
    inputs.items[inputs.currentLineId].sessionProcessedQuantity += Number(
      inputs.processingQuantity
    );
    const hasUnfinishedLines = Object.values(inputs.items).filter(
      (item) => Number(item.processedQuantity) < Number(item.quantity)
    ).length;
    setInputs((values) => ({
      ...values,
      ...{
        inventoryDetailQuantity: 0,
        inventoryDetailText: '',
        processingQuantity: 0,
        assetQuantity: 0,
        scanAssetText: '',
        items: inputs.items,
        step: hasUnfinishedLines ? STEPS.LINE_SELECTION : STEPS.SUMMARY,
        targetStep: ''
      }
    }));
  };

  const prevStep = () => {
    const { step } = inputs;
    setInputs((values) => ({
      ...values,
      step: step === STEPS.SUMMARY ? STEPS.LINE_SELECTION : step - 1
    }));
  };

  const handleChange = (event) => {
    const name = event.target.name,
      value = event.target.value;
    if (name.startsWith('items') && name.includes('-')) {
      console.log('items names', name);
      const [key, id, attr] = name.split('-');
      inputs[key][id][attr] = inputs[key][id][attr].concat(value);
      setInputs((values) => ({ ...values, [key]: values[key] }));
    } else if (['assetQuantity', 'inventoryDetailQuantity'].includes(name)) {
      inputs[name] = Number(inputs[name]) + Number(value);
      setInputs((values) => ({ ...values, [name]: inputs[name] }));
    } else if (name === 'images') {
      inputs.images = inputs.images.concat(value);
      setInputs((values) => ({ ...values, images: inputs.images }));
    } else {
      setInputs((values) => ({ ...values, [name]: value }));
    }
  };

  switch (inputs.step) {
    case -1:
      return <RefreshElement nextStep={nextStep} />;
    case STEPS.SALES_ORDER_SEARCH:
      return <SalesOrderSearch nextStep={nextStep} handleChange={handleChange} inputs={inputs} />;
    case STEPS.LINE_SELECTION:
      return (
        <LineSelection
          STEPS={STEPS}
          prevStep={prevStep}
          nextStep={nextStep}
          handleChange={handleChange}
          inputs={inputs}
        />
      );
    case STEPS.LINE_QUANTITY_SELECTION:
      return (
        <LineQuantitySelection
          prevStep={prevStep}
          nextStep={nextStep}
          handleChange={handleChange}
          inputs={inputs}
        />
      );
    case STEPS.INVENTORY_DETAIL_SELECTION:
      return (
        <LineInventoryDetailSelection
          prevStep={prevStep}
          nextStep={nextStep}
          handleChange={handleChange}
          inputs={inputs}
        />
      );
    case STEPS.LINE_ASSET_SCAN:
      return (
        <LineAssetScan
          prevStep={prevStep}
          nextStep={nextStep}
          handleChange={handleChange}
          inputs={inputs}
        />
      );
    case STEPS.SUMMARY:
      return (
        <SalesOrderSummary
          prevStep={prevStep}
          nextStep={nextStep}
          handleChange={handleChange}
          inputs={inputs}
        />
      );
    default:
      return <></>;
  }
}

function RefreshElement(props) {
  return props.nextStep();
}

export default SalesOrderForm;
