/* eslint-disable no-undef */
import axios from 'axios';
import OAuth from 'oauth-1.0a';
import crypto from 'crypto-js';

export const createHeader = (HTTP_METHOD, params = {}) => {
  try {
    const hash_function_sha256 = (base_string, key) => {
      return crypto.HmacSHA256(base_string, key).toString(crypto.enc.Base64);
    };

    const oauth = OAuth({
      consumer: {
        public: process.env.REACT_APP_CONSUMER_PUBLIC,
        secret: process.env.REACT_APP_CONSUMER_SECRET
      },
      signature_method: 'HMAC-SHA256',
      hash_function: hash_function_sha256
    });

    const oauth_data = {
      oauth_consumer_key: oauth.consumer.public,
      oauth_nonce: oauth.getNonce(),
      oauth_signature_method: oauth.signature_method,
      oauth_timestamp: oauth.getTimeStamp(),
      oauth_version: '1.0',
      oauth_token: process.env.REACT_APP_TOKEN_PUBLIC
    };

    let url = process.env.REACT_APP_RESTLET_BASE_URL;
    for (const [key, value] of Object.entries(params)) {
      url += `&${key}=${value}`;
    }

    const request_data = {
      url: url,
      method: HTTP_METHOD,
      data: {}
    };

    request_data.data = oauth_data;
    let headerWithRealm = oauth.toHeader(
      oauth.authorize(request_data, {
        public: process.env.REACT_APP_TOKEN_PUBLIC,
        secret: process.env.REACT_APP_TOKEN_SECRET
      })
    );
    headerWithRealm.Authorization += `,realm="${process.env.REACT_APP_REALM}"`;
    return headerWithRealm.Authorization;
  } catch (e) {
    console.log('error in createHeader', e);
  }
};

export const restletRequest = axios.create({
  baseURL: process.env.REACT_APP_RESTLET_BASE_URL,
  headers: {
    'Content-type': 'application/json'
  }
});
const requests = {
  restletRequest,
  createHeader
};

export default requests;

//open -n -a /Applications/Brave\ Browser.app/Contents/MacOS/Brave\ Browser --args --user-data-dir="/tmp/brave_dev_sess_1" --disable-web-security
