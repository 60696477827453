import React, { useRef } from 'react';
import { Form, Row, Col, Button, Alert } from 'react-bootstrap';
import QRCodeScan from '../../QRCodeScan';

function LineInventoryDetailSelection(props) {
  console.log('InventoryDetailSelection', props.inputs);
  const submitBtnRef = useRef();
  return (
    <>
      <h3>Inventory Detail Quantity</h3>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          props.handleChange({
            target: {
              name: `items-${props.inputs?.currentLineId}-inventoryDetails`,
              value: {
                inventoryNumber: props.inputs?.inventoryDetailText,
                quantity: props.inputs?.inventoryDetailQuantity
              }
            }
          });
          props.nextStep(e);
        }}>
        {props.inputs.errorMessage ? (
          <Row className="mb-2">
            <Alert
              variant="danger"
              onClose={() => props.handleChange({ target: { name: 'errorMessage', value: '' } })}
              dismissible>
              <Alert.Heading>You got an error!</Alert.Heading>
              <p>{props.inputs.errorMessage}</p>
            </Alert>
          </Row>
        ) : (
          <></>
        )}
        <Row className="mb-2">
          <Form.Group as={Col} controlId="inventoryDetailQuantityCount">
            <Form.Control
              readOnly
              plaintext
              defaultValue={`${props.inputs.items[props.inputs.currentLineId].name}: ${Number(props.inputs.inventoryDetailQuantity) + 1} of ${Number(props.inputs.processingQuantity)}`}
            />
          </Form.Group>
        </Row>
        <Row className="mb-2">
          <Form.Group as={Col} controlId="inventoryDetailText">
            <Form.Control
              type="text"
              name="inventoryDetailText"
              placeholder="Scan Or Enter Lot Number"
              onChange={props.handleChange}
              value={props.inputs.inventoryDetailText}
              required
            />
          </Form.Group>
        </Row>
        <Row className="mb-2 ">
          <Form.Group as={Col}>
            <div className="d-grid gap-2">
              <QRCodeScan
                inputs={props.inputs}
                fieldPath="inventoryDetailText"
                handleChange={props.handleChange}
              />
            </div>
          </Form.Group>
        </Row>
        <Row className="mb-2 ">
          <Form.Group as={Col}>
            <Form.Control
              type="number"
              name="inventoryDetailQuantity"
              placeholder="Enter Quantity"
              onChange={props.handleChange}
              required
            />
          </Form.Group>
        </Row>
        <Row className="mb-2 ">
          <Form.Group as={Col}>
            <div className="d-grid gap-2">
              {/*<Button variant="secondary" size="lg" onClick={props.prevStep}>*/}
              {/*  Back*/}
              {/*</Button>*/}
            </div>
          </Form.Group>
          <Form.Group as={Col}>
            <div className="d-grid gap-2">
              <Button ref={submitBtnRef} type="submit" variant="primary" size="lg">
                Next
              </Button>
            </div>
          </Form.Group>
        </Row>
      </Form>
    </>
  );
}

export default LineInventoryDetailSelection;
